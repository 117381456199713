import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  type FC,
  type ReactNode,
  useId,
} from 'react';
import { useBlocker } from '@remix-run/react';
import { useDisclosure } from '@mantine/hooks';

interface UnsavedChangesContextType {
  registerForm: (id: string) => void;
  unregisterForm: (id: string) => void;
  setFormDirty: (id: string, isDirty: boolean) => void;
  isModalOpen: boolean;
  handleDiscardChanges: () => void;
  handleKeepEditing: () => void;
}

const UnsavedChangesContext = createContext<UnsavedChangesContextType | undefined>(undefined);

export const UnsavedChangesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [dirtyForms, setDirtyForms] = useState<Set<string>>(new Set());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [registeredForms, setRegisteredForms] = useState<Set<string>>(new Set());
  const [isModalOpen, { open: openModal, close: closeModal }] = useDisclosure(false);

  const isDirty = dirtyForms.size > 0;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      openModal();
    }
  }, [blocker, openModal]);

  const registerForm = useCallback((id: string) => {
    setRegisteredForms((prev) => new Set(prev).add(id));
  }, []);

  const unregisterForm = useCallback((id: string) => {
    setRegisteredForms((prev) => {
      const newSet = new Set(prev);
      newSet.delete(id);
      return newSet;
    });
    setDirtyForms((prev) => {
      const newSet = new Set(prev);
      newSet.delete(id);
      return newSet;
    });
  }, []);

  const setFormDirty = useCallback((id: string, isDirty: boolean) => {
    setDirtyForms((prev) => {
      const newSet = new Set(prev);
      if (isDirty) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  }, []);

  const handleDiscardChanges = useCallback(() => {
    setDirtyForms(new Set());
    closeModal();
    blocker.proceed?.();
  }, [closeModal, blocker]);

  const handleKeepEditing = useCallback(() => {
    closeModal();
    blocker.reset?.();
  }, [closeModal, blocker]);

  const contextValue = useMemo(
    () => ({
      registerForm,
      unregisterForm,
      setFormDirty,
      isModalOpen,
      handleDiscardChanges,
      handleKeepEditing,
    }),
    [registerForm, unregisterForm, setFormDirty, isModalOpen, handleDiscardChanges, handleKeepEditing],
  );

  return <UnsavedChangesContext.Provider value={contextValue}>{children}</UnsavedChangesContext.Provider>;
};

export const useUnsavedChanges = () => {
  const context = useContext(UnsavedChangesContext);
  if (context === undefined) {
    throw new Error('useUnsavedChanges must be used within a UnsavedChangesProvider');
  }
  return context;
};

export const useFormUnsavedChanges = () => {
  const formId = useId(); // Automatically generate a unique ID
  const { registerForm, unregisterForm, setFormDirty } = useUnsavedChanges();

  useEffect(() => {
    registerForm(formId);
    return () => unregisterForm(formId);
  }, [formId, registerForm, unregisterForm]);

  const setDirty = useCallback((isDirty: boolean) => setFormDirty(formId, isDirty), [formId, setFormDirty]);

  return useMemo(() => ({ setDirty }), [setDirty]);
};
